import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import './ShortsPage.css';

const ShortsPage = ({ apiKey, channelId }) => {
  const [shorts, setShorts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const fetchShorts = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
        params: {
          part: 'snippet',
          channelId: channelId,
          maxResults: 50,
          type: 'video',
          key: apiKey,
        },
      });

      const fetchedVideos = response.data.items;

      const filteredShorts = fetchedVideos.filter(item => {
        const title = item.snippet.title.toLowerCase().trim();
        return title.includes('#shorts');
      });

      setShorts(filteredShorts);
    } catch (error) {
      setError('Error fetching shorts. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShorts();
  }, [apiKey, channelId]);

  const handleShortClick = (videoId) => {
    navigate(`/shorts/${videoId}`); // Navigate to the player page with videoId
  };

  return (
    <div className="shorts-page">
      <h2>YouTube Shorts</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="shorts-list">
          {shorts.length > 0 ? (
            shorts.map((short, index) => (
              short.snippet && short.snippet.thumbnails && (
                <div 
                  key={index} 
                  className="short" 
                  onClick={() => handleShortClick(short.id.videoId)} // Handle click
                >
                  <img
                    src={short.snippet.thumbnails.medium.url}
                    alt={short.snippet.title}
                    className="short-thumbnail"
                  />
                  <p className="short-title">{short.snippet.title}</p>
                </div>
              )
            ))
          ) : (
            <p>No Shorts available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ShortsPage;
