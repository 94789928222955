import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './About.css';

const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
const channelId = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;

const About = () => {
  const [channelInfo, setChannelInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChannelInfo = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/channels', {
          params: {
            part: 'snippet,statistics',
            id: channelId,
            key: apiKey,
          },
        });

        setChannelInfo(response.data.items[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching channel info:', error);
        setLoading(false);
      }
    };

    fetchChannelInfo();
  }, []);

  return (
    <div className="about-page">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2>About This Channel</h2>
          <p>{channelInfo.snippet?.description || 'Welcome to our YouTube channel where we share Bible studies, worship songs, and more!'}</p>

          <div className="about-details">
            <h3>Channel Stats</h3>
            <ul className="stats-list">
              <li><strong>Subscribers:</strong> {channelInfo.statistics?.subscriberCount}</li>
              <li><strong>Total Views:</strong> {channelInfo.statistics?.viewCount}</li>
              <li><strong>Total Videos:</strong> {channelInfo.statistics?.videoCount}</li>
            </ul>
          </div>

          <h3>Contact Information</h3>
          <p>
            For Any Personal Commands about this channel, please contact us at:
          </p>
          <div className="contact-icons">
            <a href="mailto:ithanan146@gmail.com" className="mail"><i className="fas fa-envelope"></i> Sone Brothers</a>
            <a href="mailto:paulsimsone16@gmail.com" className="mail"><i className="fas fa-envelope"></i> Paul Simsone</a>
            <a href="mailto:abisone2005@gmail.com" className="mail"><i className="fas fa-envelope"></i> Abisone</a>
            <a href="mailto:mahisone2008@gmail.com" className="mail"><i className="fas fa-envelope"></i> Mahisone</a>
          </div>

          <div className="subscribe-button">
            <a href={`https://www.youtube.com/channel/${channelId}?sub_confirmation=1`} target="_blank" rel="noopener noreferrer">
              <button>Subscribe</button>
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default About;
