import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import './VideoList.css';

const VideoList = ({ apiKey, channelId }) => {
  const [videos, setVideos] = useState([]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [videosPerPage] = useState(12);
  const [loading, setLoading] = useState(true);
  const [nextPageToken, setNextPageToken] = useState('');
  const [prevPageToken, setPrevPageToken] = useState('');
  const [error, setError] = useState(null);

  const fetchVideos = async (pageToken = '') => {
    setLoading(true);
    try {
      const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
        params: {
          key: apiKey,
          channelId: channelId,
          part: 'snippet,id',
          maxResults: videosPerPage,
          pageToken: pageToken,
          type: 'video',
          videoEmbeddable: 'true',
        },
      });

      const fetchedVideos = response.data.items
        .filter((item, index, self) =>
          item.id.videoId && self.findIndex(v => v.id.videoId === item.id.videoId) === index
        );

      setVideos(fetchedVideos);
      setTotalVideos(response.data.pageInfo.totalResults);
      setNextPageToken(response.data.nextPageToken || '');
      setPrevPageToken(response.data.prevPageToken || '');
      setLoading(false);
    } catch (error) {
      console.error('Error fetching videos:', error);
      setError('Failed to load videos. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handlePrevious = () => {
    if (prevPageToken) {
      setCurrentPage(currentPage - 1);
      fetchVideos(prevPageToken);
    }
  };

  const handleNext = () => {
    if (nextPageToken) {
      setCurrentPage(currentPage + 1);
      fetchVideos(nextPageToken);
    }
  };

  const totalPages = Math.ceil(totalVideos / videosPerPage);

  return (
    <div>
      <h2>Videos</h2>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <ul className="video-list">
            {videos.map((video) => (
              <li key={video.id.videoId} className="video-item">
                <Link to={`/video/${video.id.videoId}`}>
                  <img
                    src={video.snippet.thumbnails.medium.url}
                    alt={video.snippet.title}
                    className="video-thumbnail"
                  />
                  <h3>{video.snippet.title}</h3>
                </Link>
              </li>
            ))}
          </ul>

          <div className="pagination">
            <p>
              Page {currentPage} of {totalPages} | Total Videos: {totalVideos}
            </p>
            <button onClick={handlePrevious} disabled={!prevPageToken}>
              Previous
            </button>
            <button onClick={handleNext} disabled={!nextPageToken}>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default VideoList;
