// PlaylistList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PlaylistItem from './PlaylistItem'; // Ensure this is the correct path
import './PlaylistList.css'; // Optionally import your CSS file for styling

const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;

const PlaylistList = () => {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // New state for error handling

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/playlists', {
          params: {
            key: API_KEY,
            part: 'snippet',
            channelId: CHANNEL_ID, 
            maxResults: 10,
          },
        });
        console.log('Playlists Response:', response.data);
        setPlaylists(response.data.items);
      } catch (error) {
        console.error('Error fetching playlists:', error);
        setError('Failed to load playlists. Please try again later.'); // Set error message
      } finally {
        setLoading(false);
      }
    };

    fetchPlaylists();
  }, []);

  if (loading) {
    return <div className="spinner">Loading playlists...</div>; // You can replace this with a spinner component
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  if (!playlists || !Array.isArray(playlists)) {
    return <div>No playlists available.</div>;
  }

  return (
    <div className="playlist-list">
      {playlists.length > 0 ? (
        playlists.map((playlist) => (
          <PlaylistItem key={playlist.id} playlist={playlist} />
        ))
      ) : (
        <div>No playlists found.</div>
      )}
    </div>
  );
};

export default PlaylistList;
