import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './VideoPage.css';

const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

const VideoPage = () => {
  const { videoId, playlistId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [videoDetails, setVideoDetails] = useState(null);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    const fetchVideoOrPlaylist = async () => {
      setLoading(true);
      try {
        if (location.pathname.includes('/playlist')) {
          // Fetch playlist videos
          const playlistResponse = await axios.get('https://www.googleapis.com/youtube/v3/playlistItems', {
            params: {
              part: 'snippet',
              playlistId: playlistId,
              maxResults: 10,
              key: API_KEY,
            },
          });

          const playlistVideos = playlistResponse.data.items;
          setVideoDetails(playlistVideos[0]); // Set the first video as the one to play
          setRelatedVideos(playlistVideos.slice(1)); // Related videos are the rest of the playlist
        } else {
          // Fetch a single video
          const videoResponse = await axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
            params: {
              part: 'snippet,contentDetails,statistics',
              id: videoId,
              key: API_KEY,
            },
          });

          setVideoDetails(videoResponse.data.items[0]);

          const channelId = videoResponse.data.items[0].snippet.channelId;
          const relatedResponse = await axios.get(`https://www.googleapis.com/youtube/v3/search`, {
            params: {
              part: 'snippet',
              channelId: channelId,
              type: 'video',
              maxResults: 15,
              key: API_KEY,
            },
          });

          const shuffledVideos = shuffleArray(relatedResponse.data.items);
          setRelatedVideos(shuffledVideos.slice(0, 5)); // Show top 5 related videos
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching video or playlist:', error);
        setLoading(false);
      }
    };

    fetchVideoOrPlaylist();
  }, [videoId, playlistId, location.pathname]);

  const handleRelatedVideoClick = (relatedVideoId) => {
    navigate(`/video/${relatedVideoId}`);
  };

  if (loading) {
    return <div className="spinner">Loading...</div>;
  }

  return (
    <div className="video-page">
      <div className="video-section">
        <div className="video-player">
          {videoDetails && (
            <iframe
              width="100%"
              height="500"
              src={`https://www.youtube.com/embed/${videoDetails.snippet.resourceId ? videoDetails.snippet.resourceId.videoId : videoId}?autoplay=1`}
              title={videoDetails.snippet.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
        <div className="video-details">
          {videoDetails && (
            <>
              <h1>{videoDetails.snippet.title}</h1>
              <p>{videoDetails.snippet.description}</p>
              <p><strong>Tags:</strong> {videoDetails.snippet.tags?.join(', ')}</p>
            </>
          )}
        </div>
      </div>
      <div className="related-videos">
        <h2>Related Videos</h2>
        <ul>
          {relatedVideos.length ? (
            relatedVideos.map((video) => (
              <li key={video.snippet.resourceId ? video.snippet.resourceId.videoId : video.id.videoId} onClick={() => handleRelatedVideoClick(video.snippet.resourceId ? video.snippet.resourceId.videoId : video.id.videoId)}>
                <div className="thumbnail-wrapper">
                  <img 
                    src={video.snippet.thumbnails.default.url} 
                    alt={video.snippet.title} 
                  />
                </div>
                <p>{video.snippet.title}</p>
              </li>
            ))
          ) : (
            <p>No related videos found.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default VideoPage;
