import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './HomePage.css';

const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;

const HomePage = () => {
  const [channelArt, setChannelArt] = useState('');
  const [featuredVideos, setFeaturedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://www.googleapis.com/youtube/v3/channels?part=brandingSettings&id=${CHANNEL_ID}&key=${API_KEY}`)
      .then(response => {
        const channelArtUrl = response.data.items[0].brandingSettings.image.bannerExternalUrl;
        setChannelArt(channelArtUrl);
      })
      .catch(error => console.error('Error fetching channel art:', error));

    axios.get(`https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${CHANNEL_ID}&order=date&maxResults=8&key=${API_KEY}`)
      .then(response => {
        setFeaturedVideos(response.data.items);
        setLoading(false);
      })
      .catch(error => console.error('Error fetching videos:', error));
  }, []);

  if (loading) {
    return <div className="spinner">Loading...</div>;
  }

  const handleVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  return (
    <div className="home-page">
      <div className="channel-art-container">
        {channelArt && <img src={channelArt} alt="Channel Art" className="channel-art" />}
      </div>

      <section className="featured-videos">
        <h3>Featured Videos</h3>
        <div className="video-list">
          {featuredVideos.map(video => (
            <div 
              key={video.id.videoId} 
              className="video-item"
              onClick={() => handleVideoClick(video.id.videoId)}
              style={{ cursor: 'pointer' }}
            >
              <img src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} className="video-thumbnail" />
              <div className="video-info">
                <h4>{video.snippet.title}</h4>
                <p>{video.snippet.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
