import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PlaylistItem.css'; // Assuming this is your CSS file

const PlaylistItem = ({ playlist }) => {
  const navigate = useNavigate();

  const handlePlaylistClick = () => {
    navigate(`/playlist/${playlist.id}`);
  };

  return (
    <div className="playlist-item" onClick={handlePlaylistClick} role="button" tabIndex="0">
      <img 
        src={playlist.snippet?.thumbnails?.medium?.url || 'default-thumbnail.jpg'} 
        alt={playlist.snippet?.title || 'Playlist thumbnail'} 
        className="playlist-thumbnail"
      />
      <div className="playlist-details">
        <p className="playlist-title">{playlist.snippet?.title || 'Untitled Playlist'}</p>
        <p className="playlist-description">{playlist.snippet?.description || 'With God All Things Are Possible.'}</p>
      </div>
    </div>
  );
};

export default PlaylistItem;
