import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import VideoList from './pages/VideoList';
import PlaylistList from './components/Playlists/PlaylistList';
import AboutPage from './pages/AboutPage';
import VideoPage from './components/VideoPage/VideoPage';
import ShortsPage from './pages/ShortsPage';
import ShortsPlayer from './components/ShortsPlayer';
import axios from 'axios';

const App = () => {
  const location = useLocation();
  const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
  const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;
  const isVideoPage = location.pathname.startsWith('/video/') || location.pathname.startsWith('/playlist/');
  const [channelName, setChannelName] = useState('');
  const [channelLogo, setChannelLogo] = useState('');

  useEffect(() => {
    const fetchChannelData = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/channels', {
          params: {
            part: 'snippet',
            id: CHANNEL_ID,
            key: API_KEY,
          },
        });

        const fetchedChannelName = response.data.items[0].snippet.title;
        setChannelName(fetchedChannelName);
        document.title = fetchedChannelName;

        const logoUrl = response.data.items[0].snippet.thumbnails.default.url;
        setChannelLogo(logoUrl);

        const iconUrl = logoUrl;
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = iconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
      } catch (error) {
        console.error('Error fetching channel data:', error);
      }
    };

    fetchChannelData();
  }, [API_KEY, CHANNEL_ID]);

  return (
    <div class="app-container">
      {!isVideoPage && (
        <>
          <header>
            <div className="logo-container">
              {channelLogo && <img src={channelLogo} alt={`${channelName} Logo`} className="channel-logo" />}
              <h2 id="heading">{channelName}</h2>
            </div>
          </header>
          <nav>
            <ul className="nav-tabs">
              <li>
                <NavLink exact to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/videos" className={({ isActive }) => (isActive ? 'active' : '')}>
                  Videos
                </NavLink>
              </li>
              <li>
                <NavLink to="/shorts" className={({ isActive }) => (isActive ? 'active' : '')}>
                  Shorts
                </NavLink>
              </li>
              <li>
                <NavLink to="/playlists" className={({ isActive }) => (isActive ? 'active' : '')}>
                  Playlists
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>
                  About
                </NavLink>
              </li>
            </ul>
          </nav>
        </>
      )}
      <main>
        <Routes>
          <Route path="/videos" element={<VideoList apiKey={API_KEY} channelId={CHANNEL_ID} />} />
          <Route path="/video/:videoId" element={<VideoPage />} />
          <Route path="/playlist/:playlistId" element={<VideoPage />} />
          <Route path="/shorts" element={<ShortsPage apiKey={API_KEY} channelId={CHANNEL_ID} />} />
          <Route path="/playlists" element={<PlaylistList channelId={CHANNEL_ID} />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </main>
      <footer className="footer">
        <strong>Developed By Abisone</strong>
      </footer>
    </div>
  );
};

export default App;
